import { useState } from "react";

export const CardSection = () => {
  const openPop = (El: HTMLElement) => {
    const article = El.closest("article");
    if (article) {
      article.classList.add("hidden");
      const nextArticle = article.nextElementSibling;
      if (nextArticle) {
        nextArticle.classList.remove("hidden");
      }
    }
  };

  const closePop = (El: HTMLElement) => {
    const article = El.closest("article");
    if (article) {
      article.classList.add("hidden");
      const prevArticle = article.previousElementSibling;
      if (prevArticle) {
        prevArticle.classList.remove("hidden");
      }
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <section className="bg-gray-50">
      <article
        id="scroll_1"
        className="py-56 max-w-screen-lg mx-auto max-md:py-24 max-md:px-6 "
      >
        <div className="pb-22 max-md:pb-14">
          <h3 className="text-2xl font-bold text-green pb-8 max-md:text-xl">
            D.SHARE Brand
          </h3>
          <p className="font-bold text-5xl text-black max-md:text-mo-lg">
            디쉐어는 진정성 있는 <br className="hidden max-md:block" />
            교육 서비스를 <br className="hidden max-md:block" />
            제공합니다.
          </p>
        </div>
        <div>
          <ul
            className="md:flex"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="flex">
              <li
                className={`badge ${
                  isHovered
                    ? "bg-gray text-black"
                    : "bg-green text-white font-bold"
                } md:hover:hover-brand-green max-md:rounded-lg max-md:mr-2 max-md:bg-white max-md:text-green max-md:font-normal max-md:py-2 max-md:px-3 max-md:text-base`}
              >
                <span className="hidden max-md:block">에이닷 영어학원</span>
                <a
                  href="https://www.adotenglish.com/html/main"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="block max-md:hidden">에이닷 영어학원</span>
                </a>
              </li>
              <li className="badge text-black bg-gray md:hover:hover-brand-green max-md:rounded-lg max-md:mr-0 max-md:bg-white max-md:text-green max-md:font-normal max-md:py-2 max-md:px-3 max-md:text-base">
                <span className="hidden max-md:block">에이닷온 영어</span>
                <a
                  href="https://www.adotonenglish.com/student/index"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="block max-md:hidden">에이닷온 영어</span>
                </a>
              </li>
            </div>
            <div className="flex max-md:pt-3">
              <li className="badge text-black bg-gray md:hover:hover-brand-green max-md:rounded-lg max-md:mr-0 max-md:bg-white max-md:text-green max-md:font-normal max-md:py-2 max-md:px-3 max-md:text-base">
                <span className="hidden max-md:block">에이닷 수학학원</span>
                <a
                  href="https://www.adotmath.com/html/main"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="block max-md:hidden">에이닷 수학학원</span>
                </a>
              </li>
              <li className="badge text-black bg-gray md:hover:hover-brand-green max-md:rounded-lg max-md:mr-0 max-md:bg-white max-md:text-green max-md:font-normal max-md:mx-2.5 max-md:py-2 max-md:px-3 max-md:text-base">
                <span className="hidden max-md:block">단끝</span>
                <a
                  href="https://www.vocaending.co.kr/html/main"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="block max-md:hidden">단끝</span>
                </a>
              </li>
              <li className="badge text-black bg-gray md:hover:bg-green md:hover:text-white md:hover:font-bold max-md:rounded-lg max-md:mr-0 max-md:bg-white max-md:text-green max-md:font-normal max-md:mx-2.5 max-md:py-2 max-md:px-3 max-md:text-base">
                <span className="hidden max-md:block">리딧</span>
                <a
                  href="https://www.read-it.co.kr/html/main"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="block max-md:hidden">리딧</span>
                </a>
              </li>
            </div>
          </ul>
          <div className="pt-12">
            <div className="md:flex block">
              <article className="w-1/2 max-md:w-full">
                <article className="mb-12 bg-white rounded-md drop-shadow-front max-md:max-w-[32.5rem] max-md:mr-auto">
                  <div className="px-10 pt-10 pb-14 max-md:p-6">
                    <div className="flex justify-between items-center pb-10 max-md:pb-0 max-md:h-6">
                      <div>
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/adot.png"
                          alt="에이닷 영어학원 로고"
                          className="max-md:h-6"
                        />
                      </div>
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/plus.png"
                          alt="더보기"
                          onClick={(event) =>
                            openPop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl max-md:py-28">
                      온·오프라인 블렌디드
                      <br />
                      1:1 영어학습 전문 브랜드
                    </h3>
                  </div>
                  <div className="w-full">
                    <img
                      src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/에이닷 영어학원.png"
                      alt="온·오프라인 블렌디드 1:1 영어학습 전문 브랜드"
                      className="w-full"
                    />
                  </div>
                </article>
                <article className="mb-12 bg-adot bg-center rounded-md drop-shadow-back max-md:h-auto max-md:max-w-[32.5rem] max-md:mr-auto hidden">
                  <div className="h-35 px-10 pt-10 pb-16 max-md:p-6 max-md:h-auto">
                    <div className="flex justify-between items-center pb-10 max-md:pb-7">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/adot.png"
                        alt="에이닷 영어학원 로고"
                        className="max-md:h-6"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/closebtn.png"
                          alt="닫기"
                          onClick={(event) =>
                            closePop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      온·오프라인 블렌디드
                      <br />
                      1:1 영어학습 전문 브랜드
                    </h3>
                    <p className="pt-14 text-base flex flex-wrap md:text-xl max-md:pt-8 max-md:block">
                      <span>에이닷 영어학원은 온·오프라인이 결합된 러닝 학습</span>
                      <span>환경을 구축하여 최적의 개인 맞춤 학습을 제공하는 </span>
                      <span>‘1:1 프리미엄 교육 브랜드’ 입니다. </span>
                      <span>90.98%의 높은 성적 향상률과 96.45%의 학습 </span>
                      <span>습관 개선율을 자랑하는 에이닷 영어학원은 전국 </span>
                      <span>80개 지점에서 만나볼 수 있습니다.</span>
                    </p>                    
                    <a
                      href="https://www.adotenglish.com/html/main"
                      className="block pt-20 text-green font-bold text-xl max-md:pt-5 max-md:text-base"
                      target="_blank"
                      rel="noreferrer"
                    >
                      에이닷 영어학원 홈페이지 바로가기
                    </a>
                  </div>
                </article>
                <article className="mb-12 bg-white rounded-md drop-shadow-front max-md:max-w-[32.5rem] max-md:mr-auto">
                  <div className="px-10 pt-10 pb-14 max-md:p-6">
                    <div className="flex justify-between items-center pb-10 max-md:pb-0 max-md:h-6">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/adotOn.png"
                        alt="에이닷온 로고"
                        className="max-md:h-6"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/plus.png"
                          alt="더보기"
                          onClick={(event) =>
                            openPop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl max-md:py-28">
                      온라인
                      <br />
                      1:1 영어학습 전문 브랜드
                    </h3>
                  </div>
                  <div>
                    <img
                      src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/에이닷온.png"
                      alt="온라인 1:1 영어학습 전문 브랜드"
                      className="w-full"
                    />
                  </div>
                </article>
                <article className="mb-12 bg-adotOn bg-center rounded-md drop-shadow-front max-md:h-auto max-md:max-w-[32.5rem] max-md:mr-auto hidden">
                  <div className="h-35 px-10 pt-10 pb-16 max-md:p-6 max-md:h-auto">
                    <div className="flex justify-between items-center pb-10 max-md:pb-7">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/adotOn.png"
                        alt="에이닷온 로고"
                        className="max-md:h-6"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/closebtn.png"
                          alt="닫기"
                          onClick={(event) =>
                            closePop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      온라인
                      <br />
                      1:1 영어학습 전문 브랜드
                    </h3>
                    <p className="pt-14 text-base flex flex-wrap md:text-xl max-md:pt-8 max-md:block">
                      <span>에이닷온 영어는 공간의 제약을 벗어난 1:1 온라인 </span>
                      <span>영어학원입니다. </span>
                      <span>에이닷온 영어에서는 AI 학습 내비게이션으로 </span>
                      <span>최적의 학습 경로를 설정하고, 취약점을 분석해 주는 </span>
                      <span>AI 코치와 1:1 화상 수업으로 완전 맞춤 학습을 </span>
                      <span>제공하는 휴먼 코치(담당 선생님)가 학생의 학습 </span>
                      <span>성취를 위한 관리를 제공합니다.</span>
                    </p>  
                    <a
                      href="https://www.adotonenglish.com/student/index"
                      className="block pt-20 text-green font-bold text-xl max-md:pt-5 max-md:text-base"
                      target="_blank"
                      rel="noreferrer"
                    >
                      에이닷온 홈페이지 바로가기
                    </a>
                  </div>
                </article>
                <article className="mb-12 bg-white rounded-md drop-shadow-front max-md:max-w-[32.5rem] max-md:mr-auto">
                  <div className="px-10 pt-10 pb-14 max-md:p-6">
                    <div className="flex justify-between items-center mb-10">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/adotMath.png"
                        alt="에이닷 수학학원 로고"
                        className="max-md:h-6"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/plus.png"
                          alt="더보기"
                          onClick={(event) =>
                            openPop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      온라인
                      <br />
                      1:1 수학학습 전문 브랜드
                    </h3>
                  </div>
                  <div>
                    <img
                      src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/에이닷 수학학원.png"
                      alt="온라인 1:1 수학학습 전문 브랜드"
                      className="w-full"
                    />
                  </div>
                </article>
                <article className="mb-12 bg-adotMath bg-center rounded-md drop-shadow-front max-md:h-auto max-md:max-w-[32.5rem] max-md:mr-auto hidden">
                  <div className="h-35 px-10 pt-10 pb-16 max-md:p-6 max-md:h-auto">
                    <div className="flex justify-between items-center mb-10">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/adotMath.png"
                        alt="에이닷 수학학원 로고"
                        className="max-md:h-6"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/closebtn.png"
                          alt="닫기"
                          onClick={(event) =>
                            closePop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      온라인
                      <br />
                      1:1 수학학습 전문 브랜드
                    </h3>
                    <p className="pt-14 text-base flex flex-wrap md:text-xl max-md:pt-8 max-md:block">
                      <span>에이닷 수학학원은 학생들에게 1:1 개별 맞춤 화상 </span>
                      <span>관리를 제공하여 학생들을 수학 학습의 올바른</span>
                      <span>방향으로 안내합니다. </span>
                      <span>모든 문제 풀이의 기본은 개념이라는 일념 하에 </span>
                      <span>확실한 개념완성을 돕기 위한 커리큘럼과 기억력을 </span>
                      <span>제공하는 휴먼 코치(담당 선생님)가 학생의 학습 </span>
                      <span>극대화하는 메타인지 테스트를 제공합니다.</span>
                    </p>  
                    <a
                      href="https://www.adotmath.com/html/main"
                      className="block pt-20 text-green font-bold text-xl max-md:pt-5 max-md:text-base"
                      target="_blank"
                      rel="noreferrer"
                    >
                      에이닷 수학학원 홈페이지 바로가기
                    </a>
                  </div>
                </article>
              </article>
              <article className="w-2/4 h-full md:ml-5 md:pt-56 max-md:w-full">
                <article className="md:h-35 mb-12 bg-white rounded-md drop-shadow-front max-md:max-w-[32.5rem] max-md:mr-auto">
                  <div className="px-10 pt-10 pb-14 max-md:p-6">
                    <div className="flex justify-between items-center mb-5">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/vocaending.png"
                        alt="단끝 로고"
                        className="max-md:h-8"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/plus.png"
                          alt="더보기"
                          onClick={(event) =>
                            openPop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      단어 학습
                      <br />
                      전문 브랜드
                    </h3>
                  </div>
                  <div>
                    <img
                      src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/단끝_v3.png"
                      alt="단어 학습 전문 브랜드"
                      className="w-full"
                    />
                  </div>
                </article>
                <article className="h-35 mb-12 bg-vocaEnding bg-center rounded-md drop-shadow-front max-md:h-auto max-md:max-w-[32.5rem] max-md:mr-auto hidden">
                  <div className="h-35 px-10 pt-10 pb-16 max-md:p-6 max-md:h-auto">
                    <div className="flex justify-between items-center mb-5">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/vocaending.png"
                        alt="단끝 로고"
                        className="max-md:h-8"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/closebtn.png"
                          alt="닫기"
                          onClick={(event) =>
                            closePop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      단어 학습
                      <br />
                      전문 브랜드
                    </h3>
                    <p className="pt-14 text-base md:text-xl max-md:pt-8 max-md:flex max-md:flex-wrap">
                      <span>
                        단끝은 학생들의 효율적인 영단어 암기를 위해 다양한<br className="block max-md:hidden" /> 학습 방법을 제공하는 ‘스마트 단어 학습 브랜드‘입니다.
                        <br className="block max-md:hidden" /> 영단어장 베스트셀러 8권과 수능 및 평가원 모의고사<br className="block max-md:hidden" /> 3개년의 필수 영단어 약 9,600여 개를 앱 하나에 
                        <br className="block max-md:hidden" /> 모두 수록하여 언제 어디서든 효율적으로 <br className="block max-md:hidden" />
                        암기할 수 있습니다.
                      </span>
                    </p>
                    <a
                      href="https://www.vocaending.co.kr/html/main"
                      className="block pt-16 text-green font-bold text-xl max-md:pt-5 max-md:text-base"
                      target="_blank"
                      rel="noreferrer"
                    >
                      단끝 홈페이지 바로가기
                    </a>
                  </div>
                </article>
                <article className="md:h-35 mb-12 bg-white rounded-md drop-shadow-front max-md:max-w-[32.5rem] max-md:mr-auto max-md:h-auto">
                  <div className="px-10 pt-10 pb-16 max-md:p-6 max-md:h-auto">
                    <div className="flex justify-between items-center mb-10">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/readit.png"
                        alt="리딧 로고"
                        className="max-md:h-4"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/plus.png"
                          alt="더보기"
                          onClick={(event) =>
                            openPop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      출판
                      <br />
                      전문 브랜드
                    </h3>
                  </div>
                  <div>
                    <img
                      src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/리딧.png"
                      alt="출판 브랜드"
                      className="w-full"
                    />
                  </div>
                </article>
                <article className="mb-12 bg-readit bg-center rounded-md drop-shadow-front max-md:h-auto max-md:max-w-[32.5rem] max-md:mr-auto hidden">
                  <div className="h-35 px-10 pt-10 pb-16 max-md:p-6 max-md:h-auto">
                    <div className="flex justify-between items-center mb-10">
                      <img
                        src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/logo/readit.png"
                        alt="리딧 로고"
                        className="max-md:h-4"
                      />
                      <div className="cursor-pointer">
                        <img
                          src="https://d3157wx5zs7nsu.cloudfront.net/3j_corp/renewal/img/closebtn.png"
                          alt="닫기"
                          onClick={(event) =>
                            closePop(event.target as HTMLElement)
                          }
                          className="max-md:h-6"
                        />
                      </div>
                    </div>
                    <h3 className="font-bold text-3xl max-md:text-2xl">
                      출판
                      <br />
                      전문 브랜드
                    </h3>
                    <p className="pt-14 text-base md:text-xl max-md:pt-8 max-md:flex max-md:flex-wrap">
                      <span>
                      리딧은 디쉐어 브랜드의 모든 자체 제작 교재의 <br className="block max-md:hidden" />
                      출판을 담당하는 출판 전문 브랜드입니다. <br className="block max-md:hidden" />
                      리딧을 통해 내신과 수능을 관통하는 다양한 <br className="block max-md:hidden" />콘텐츠들을 학생들에게 전달하고 있습니다.
                      <br className="block max-md:hidden" />
                      </span>
                    </p>
                    <a
                      href="https://www.read-it.co.kr/html/main"
                      className="block pt-20 text-green font-bold text-xl max-md:pt-5 max-md:text-base"
                      target="_blank"
                      rel="noreferrer"
                    >
                      리딧 홈페이지 바로가기
                    </a>
                  </div>
                </article>
              </article>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};
