import { TitleSection } from "./TitleSection";
import { VideoSection } from "./VideoSection";
import { HistorySection } from "./HistorySection";
import { CardSection } from "./CardSection";
import { RecruitSection } from "./RecruitSection";
import { NewsSection } from "./NewsSection";
import { CSRSection } from "./CSRSection";

export const MainPage = () => {
  return (
    <main>
      <TitleSection></TitleSection>
      <VideoSection></VideoSection>
      <HistorySection></HistorySection>
      <CardSection></CardSection>
      <RecruitSection></RecruitSection>
      <NewsSection></NewsSection>
      <CSRSection></CSRSection>
    </main>
  );
};
